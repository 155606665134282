import {useParams} from "react-router";
import useSWR, { mutate } from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import Debug from "~/components/Debug";
import PageHeader from "~/components/PageHeader";
import {Link} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import React, { useState } from "react";
import { Avatar, Button, Heading, Table, Text, Card, Badge, Flex, Box, Dialog, Popover, Tabs } from "@radix-ui/themes";
import { avatarLetters } from "./CustomersListPage";
import { formatDateTime } from "~/utils/dateTime";
import { ExternalLinkIcon, CreditCard, Clock, Building2, Mail, Phone, Code, RefreshCw } from "lucide-react";

interface Customer {
  id: string;
  name?: string;
  email: string;
  phone?: string;
  created_at: string;
  reference_id?: string;
  twin?: {
    connector_link?: string;
  };
  hostname?: string;
  subscriptions?: any[];
}

interface UsagePeriod {
  period_start_at: string;
  period_end_at: string;
  amount: number;
  currency: string;
  fees: Array<{
    amount: string;
    description: string | null;
    usage: number;
    charge?: {
      name: string;
      currency: string;
      type: string;
      amount_minimum_spend: string;
      minimum_billable_usage: string | null;
      amount: string;
    };
    metric?: {
      id: string;
      event_name: string;
      aggregation: string;
      type: string;
    };
  }>;
}

interface PaginatedResponse<T> {
  data: T[];
  meta: {
    current_page: number;
    per_page: number;
    total?: number;
    last_page: number;
  };
  links: {
    next?: string;
    prev?: string;
  };
}

interface CustomerEvent {
  id: string;
  event_name: string;
  unique_id: string;
  properties: Record<string, any>;
  created_at: string;
  metadata?: Record<string, any>;
}

function formatCurrency(amount: number | string, currency: string) {
  const numericAmount = typeof amount === 'string' ? parseInt(amount, 10) : amount;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericAmount / 100);
}

function formatDateOnly(dateStr: string) {
  return new Date(dateStr).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}

function DateWithTooltip({ date }: { date: string }) {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <Text className="cursor-help border-b border-dotted border-gray-300">
          {formatDateOnly(date)}
        </Text>
      </Popover.Trigger>
      <Popover.Content>
        <Flex gap="2" align="center">
          <Clock className="w-3 h-3" />
          <Text size="1">{formatDateTime(date)}</Text>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}

function ScheduleSummary({ subscriptions }) {
  return (
    <Card className="mt-4">
      <Flex justify="between" align="center" mb="4">
        <Heading size="3">Subscriptions</Heading>
        <Badge size="1" variant="soft" color="gray">{subscriptions.length}</Badge>
      </Flex>
      {subscriptions.length === 0 ? (
        <Text color="gray">No subscriptions found</Text>
      ) : (
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Plan</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Currency</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Renewal</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {subscriptions.map((subscription) => (
              <Table.Row key={subscription.id || subscription.plan?.id}>
                <Table.Cell>
                  {subscription.plan ? (
                    <Link to={`/catalog/pricing/plans/${subscription.plan.id}`} className="text-link hover:underline">
                      {subscription.plan.name} 
                    </Link>
                  ) : (
                    'No plan found'
                  )}
                  <Badge size="1" variant="soft" ml="2">x{subscription.quantity}</Badge>
                </Table.Cell>
                <Table.Cell>
                  <Badge
                    size="1"
                    color={subscription.current_state === 'active' ? 'green' : 'gray'}
                  >
                    {subscription.current_state}
                  </Badge>
                </Table.Cell>
                <Table.Cell>{subscription.plan?.currency_code || '-'}</Table.Cell>
                <Table.Cell>{subscription.plan?.renew_interval || '-'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      )}
    </Card>
  )
}

function JsonViewDialog({ data, open, onOpenChange }: { data: any, open: boolean, onOpenChange: (open: boolean) => void }) {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content size="4">
        <Dialog.Title>Raw Data</Dialog.Title>
        <div className="mt-4">
          <pre className="bg-gray-50 p-4 rounded-lg overflow-auto max-h-[500px]">
            {JSON.stringify(data, null, 2)}
          </pre>
        </div>
        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft">Close</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

function CurrentUsage({ usage }: { usage: UsagePeriod }) {
  const [showJson, setShowJson] = useState(false);

  return (
    <Card className="mt-2">
      <Flex direction="column" gap="4">
        <Flex justify="between" align="center">
          <div>
            <Flex align="center" gap="2" mb="1">
              <Heading size="3">Current Period</Heading>
              <Button variant="ghost" size="1" onClick={() => setShowJson(true)}>
                <Code className="w-3 h-3" />
                <Text size="1">Show JSON</Text>
              </Button>
            </Flex>
            <Flex gap="2" align="center">
              <DateWithTooltip date={usage.period_start_at} />
              <Text color="gray">–</Text>
              <DateWithTooltip date={usage.period_end_at} />
            </Flex>
          </div>
          <div className="text-right">
            <Text size="2" color="gray" mb="2">Current Amount:</Text>
            <Text size="6" weight="bold" className={usage.amount > 0 ? 'text-green-600' : ''}>
              {formatCurrency(usage.amount, usage.currency)}
            </Text>
          </div>
        </Flex>
        
        {usage.fees.length > 0 && (
          <div>
            <Text weight="medium" mb="2" size="2">Usage Breakdown</Text>
            <Table.Root>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Usage</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell>Metric</Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {usage.fees.map((fee, index) => (
                  <Table.Row key={index}>
                    <Table.Cell className="font-medium">{fee.description || fee.charge?.name || 'N/A'}</Table.Cell>
                    <Table.Cell>
                      <Badge size="1" variant="soft">
                        {fee.usage}
                      </Badge>
                    </Table.Cell>
                    <Table.Cell>
                      <Text weight="medium" className="whitespace-nowrap">
                        {formatCurrency(fee.amount, fee.charge?.currency || usage.currency)}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      {fee.metric && (
                        <Badge size="1" variant="surface">
                          {fee.metric.event_name} ({fee.metric.aggregation})
                        </Badge>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </div>
        )}
      </Flex>
      <JsonViewDialog data={usage} open={showJson} onOpenChange={setShowJson} />
    </Card>
  );
}

function UsageSection({ customer, usageError }: { customer: Customer, usageError: any }) {
  const [selectedSubscription, setSelectedSubscription] = useState<string | null>(null);
  const subscriptions = customer.subscriptions || [];
  
  const url = selectedSubscription 
    ? `/customers/${customer.id}/usage?subscription=${selectedSubscription}`
    : `/customers/${customer.id}/usage`;

  const { data: usage, error: currentUsageError } = useSWR<UsagePeriod>(url, getFetcher);

  const handleSubscriptionChange = (value: string) => {
    setSelectedSubscription(value === 'all' ? null : value);
  };

  return (
    <div className="space-y-6">
      <Tabs.Root 
        defaultValue="all" 
        className="w-full"
        onValueChange={handleSubscriptionChange}
      >
        <Tabs.List>
          <Tabs.Trigger value="all">All Usage</Tabs.Trigger>
          {subscriptions.map((subscription) => (
            <Tabs.Trigger 
              key={subscription.id} 
              value={subscription.id}
            >
              {subscription.plan?.name || 'Unnamed Plan'}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </Tabs.Root>

      {usage && <CurrentUsage usage={usage} />}
      {(usageError || currentUsageError) && (
        <Card>
          <Text color="red">Error: {(usageError || currentUsageError)?.response?.data?.message || 'Error fetching usage'}</Text>
        </Card>
      )}

      {customer.subscriptions && (
        <ScheduleSummary subscriptions={customer.subscriptions}/>
      )}

      {customer.id && <HistoricalUsage 
        customerId={customer.id} 
        subscriptionId={selectedSubscription}
      />}
    </div>
  );
}

function HistoricalUsage({ customerId, subscriptionId }: { customerId: string, subscriptionId: string | null }) {
  const [page, setPage] = useState(1);
  const [selectedPeriod, setSelectedPeriod] = useState<UsagePeriod | null>(null);
  
  const url = subscriptionId 
    ? `/customers/${customerId}/usage/past?page=${page}&subscription=${subscriptionId}`
    : `/customers/${customerId}/usage/past?page=${page}`;
    
  const { data: historicalUsage } = useSWR<PaginatedResponse<UsagePeriod>>(url, getFetcher);

  if (!historicalUsage?.data?.length) {
    return (
      <Card className="mt-4">
        <Text color="gray">No historical usage data available.</Text>
      </Card>
    );
  }

  return (
    <Card className="mt-4">
      <Flex direction="column" gap="4">
        <Flex justify="between" align="center">
          <Heading size="3">Usage History</Heading>
          <Flex gap="2" align="center">
            <Button 
              variant="soft" 
              disabled={!historicalUsage.links.prev}
              onClick={() => setPage(page - 1)}
            >
              Previous
            </Button>
            <Badge variant="surface">
              {historicalUsage.meta.current_page} of {historicalUsage.meta.last_page}
            </Badge>
            <Button 
              variant="soft"
              disabled={!historicalUsage.links.next}
              onClick={() => setPage(page + 1)}
            >
              Next
            </Button>
          </Flex>
        </Flex>

        <div className="grid gap-4">
          {historicalUsage.data.map((period) => (
            <Card key={`${period.period_start_at}-${period.period_end_at}`} variant="surface">
              <Flex direction="column" gap="3">
                <Flex justify="between" align="center">
                  <div>
                    <Flex align="center" gap="2" mb="1">
                      <Text size="2" color="gray">Billing Period</Text>
                      <Button variant="ghost" size="1" onClick={() => setSelectedPeriod(period)}>
                        <Code className="w-3 h-3" />
                        <Text size="1">Show JSON</Text>
                      </Button>
                    </Flex>
                    <Flex gap="2" align="center">
                      <DateWithTooltip date={period.period_start_at} />
                      <Text color="gray">–</Text>
                      <DateWithTooltip date={period.period_end_at} />
                    </Flex>
                  </div>
                  <div className="text-right">
                    <Text size="2" color="gray" mb="2">Total Amount:</Text>
                    <Text size="4" weight="bold" className={period.amount > 0 ? 'text-green-600' : ''}>
                      {formatCurrency(period.amount, period.currency)}
                    </Text>
                  </div>
                </Flex>
                
                {period.fees.length > 0 && (
                  <div>
                    <Table.Root>
                      <Table.Header>
                        <Table.Row>
                          <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
                          <Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {period.fees.map((fee, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>{fee.description || fee.charge?.name || 'N/A'}</Table.Cell>
                            <Table.Cell>
                              <Text weight="medium" className="whitespace-nowrap">
                                {formatCurrency(fee.amount, period.currency)}
                              </Text>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table.Root>
                  </div>
                )}
              </Flex>
            </Card>
          ))}
        </div>
      </Flex>
      <JsonViewDialog 
        data={selectedPeriod} 
        open={!!selectedPeriod} 
        onOpenChange={(open) => !open && setSelectedPeriod(null)} 
      />
    </Card>
  );
}

function CustomerCard({ customer }: { customer: Customer }) {
  const [isAdditionalDetailsVisible, setIsAdditionalDetailsVisible] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncResult, setSyncResult] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  const handleSync = async () => {
    setIsSyncing(true);
    setSyncResult(null);
    try {
      await apiClient.patch(`/customers/${customer.id}`, {});
      await mutate(`/customers/${customer.id}`);
      setSyncResult({ type: 'success', message: 'Customer synced successfully' });
    } catch (error) {
      setSyncResult({ 
        type: 'error', 
        message: error?.response?.data?.message || 'Failed to sync customer'
      });
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <Card className="w-[400px]">
      <Flex direction="column" gap="4">
        <Flex align="center" gap="4">
          <Avatar
            src={customer.hostname ? `https://logo-service.plandalf.dev/api/logo?url=${customer.hostname}` : null}
            fallback={avatarLetters(customer)}
            size="4"
            className="rounded-xl"
          />
          <div className="flex-grow">
            <Heading size="4">{customer.name || customer.email}</Heading>
            <Text size="2" color="gray" className="mt-1">Customer since {formatDateTime(customer.created_at)}</Text>
          </div>
          <Button 
            size="1"
            disabled={isSyncing}
            onClick={handleSync}
          >
            <RefreshCw className={`w-3 h-3 ${isSyncing ? 'animate-spin' : ''}`} />
            Sync
          </Button>
        </Flex>

        <div className="space-y-4">
          <Flex align="center" gap="2">
            <Building2 className="w-4 h-4 text-gray-500" />
            <Text>{customer.name || '-'}</Text>
          </Flex>

          <Flex align="center" gap="2">
            <Mail className="w-4 h-4 text-gray-500" />
            <Text>{customer.email}</Text>
          </Flex>

          <Flex align="center" gap="2">
            <Phone className="w-4 h-4 text-gray-500" />
            <Text>{customer.phone || "-"}</Text>
          </Flex>

          <Flex align="center" gap="2">
            <CreditCard className="w-4 h-4 text-gray-500" />
            <Link target="_blank" to={customer.twin?.connector_link} className="text-link hover:underline flex gap-1 items-center">
              {customer.reference_id}
              <ExternalLinkIcon className="w-4 h-4" />
            </Link>
          </Flex>
        </div>

        {isAdditionalDetailsVisible && (
          <Box mt="4">
            <Text size="2" weight="medium" mb="2">Additional Details</Text>
            <Debug {...customer}/>
          </Box>
        )}

        <Button 
          variant="ghost"
          onClick={() => setIsAdditionalDetailsVisible(!isAdditionalDetailsVisible)}
        >
          {isAdditionalDetailsVisible ? 'Hide Details' : 'Show Details'}
        </Button>
      </Flex>

      {syncResult && (
        <div className={`
          fixed bottom-4 right-4 p-4 rounded-lg shadow-lg z-50
          ${syncResult.type === 'success' ? 'bg-green-50 border border-green-200' : 'bg-red-50 border border-red-200'}
        `}>
          <div className={`font-medium ${syncResult.type === 'success' ? 'text-green-900' : 'text-red-900'}`}>
            {syncResult.type === 'success' ? 'Success' : 'Error'}
          </div>
          <div className={syncResult.type === 'success' ? 'text-green-700' : 'text-red-700'}>
            {syncResult.message}
          </div>
        </div>
      )}
    </Card>
  );
}

export default function CustomerDetailPage() {
  const {customerId} = useParams();

  const { data: customer, error } = useSWR<Customer>(`/customers/${customerId}`, getFetcher);
  const { data: customerEvents, error: customerEventsError } = useSWR<PaginatedResponse<CustomerEvent>>(`/usage/events?customer=${customerId}`, getFetcher);

  if (!customer) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="p-6">
        <PageHeader
          navigation={<Link className="flex items-center" to={`/customers`}><ChevronLeftIcon className="w-6 h-6 text-black" /></Link>}
        >
          <Heading>Customer Overview</Heading>
        </PageHeader>

        <div className="flex gap-6 mt-6">
          <CustomerCard customer={customer} />

          <div className="flex-grow space-y-6">
            <UsageSection 
              customer={customer}
              usageError={null}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
